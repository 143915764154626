import React from 'react'
import PrimaryVolumeCard from './Common/PrimaryVolumeCard/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {useRecoilState} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'

const OverviewProtectionValueSummary = () => {
	const {getProtectionValueSummary} = usePulseApi()
	const value = useApiValue(filterState, getProtectionValueSummary, 0)
	const [coreCurrency] = useRecoilState(selectedCoreCurrencyState)

	return (
		<div className="relative">
			<div
				className="
					-top-3 absolute px-2 left-4 z-10
					bg-white
					text-xs uppercase
					border border-pulse-icon-gray rounded-full
					cursor-pointer"
			>
				<span className="
					align-middle mr-1
					leading-5 text-base text-pulse-icon-gray
					material-symbols-outlined"
				>
					visibility
				</span>
				<span className="
					align-middle
					text-pulse-icon-gray text-xs">PG Only</span>
			</div>
			<PrimaryVolumeCard
				value={formatCurrency(coreCurrency, value.data)}
				header="Protect Group Revenue"
				icon="account_balance_wallet"
				tooltipText="This refers to the share of revenue that PG receives from the Refund Protect service. "
				className="bg-pulse-highlight-red"
				valueClassName="text-pulse-red"
				isLoading={value.isLoading}
			/>
		</div>
	)
}

export default OverviewProtectionValueSummary
