import React from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {dashboardPaths, Dashboards} from '../../../types'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import DashboardFilter from './DashboardFilter'
import {signOut} from '../../../services/user-service'
import {useRecoilValue} from 'recoil'
import {dashboardLoadingState, userDetailState} from '../../../state/pulse'
import Logo from './Common/Images/Logo'

const DashboardHeader = () => {
	const dashboardLoading = useRecoilValue(dashboardLoadingState)

	return (
		<>
			<div className="relative mb-28 z-100 lg:mb-40">
				<header
					className={classNames([
						'fixed top-0 w-full z-20',
						'bg-white text-xs',
						'transition ease-in-out',
						'data-[closed]:opacity-0',
						'data-[enter]:duration-500 data-[enter]:data-[closed]:-translate-y-full',
						'data-[leave]:duration-300 data-[leave]:data-[closed]:-translate-y-full',
					])}
				>
					<div className="max-w-6xl mx-auto">
						<div className="
							flex items-center justify-between px-4
							lg:px-10 lg:py-0"
						>
							<div className="flex flex-shrink-0 items-center mr-6">
								<Logo/>
							</div>
							<HeaderContent/>
						</div>
						{!dashboardLoading && (
							<DashboardFilter/>
						)}
						<div className="
								border-gray-200
								lg:border-b lg:mx-10"
						/>
					</div>
				</header>
			</div>
		</>
	)
}

const HeaderContent = () => {
	const userDetail = useRecoilValue(userDetailState)
	const handleLogOut = async () => await signOut()

	return (
		<div className="flex flex-grow items-center w-auto">
			<nav className="
				flex-grow hidden
				tracking-[1px] uppercase
				lg:block"
			>
				<HeaderLink text="Overview" dashboard={Dashboards.PulseOverview}/>
				<HeaderLink text="Revenue Trends" dashboard={Dashboards.PulseRevenueTrends}/>
				<HeaderLink text="Customer Trends" dashboard={Dashboards.PulseCustomerTrends}/>
				<HeaderLink text="Transactions" dashboard={Dashboards.PulseTransactions}/>
			</nav>
			<div className="ml-auto">
				<Popover>
					<PopoverButton className="
						inline-flex items-center justify-center min-h-16 w-full
						outline-none
						hover:text-pulse-blue"
					>
						<span className="
							block
							tracking-[1px] uppercase"
						>
							{userDetail.memberName}
						</span>
						<div className="pt-[4px]">
							<span className="material-symbols-outlined">
								arrow_drop_down
							</span>
						</div>
						<div className="
							hidden my-2 p-1
							border border-pulse-icon-gray rounded-full
							lg:block"
						>
							<span className="
								h-8 w-8
								text-2xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								person
							</span>
						</div>
					</PopoverButton>
					<PopoverPanel anchor="bottom end" className="text-xs z-20">
						<div className="
							m-[2px] mt-0 relative
							bg-white
							border border-pulse-popup-border-gray rounded-lg"
						>
							<div className="flex flex-col px-5 py-4">
								<div className="mb-2">{userDetail.userName}</div>
								<NavLink
									to={dashboardPaths[Dashboards.PulseSettings]}
									className="
										inline-flex
										items-center
										cursor-pointer
										hover:text-pulse-blue"
								>
									<span className="
										mr-1
										text-xl
										material-symbols-outlined">tune</span>
									<span>Settings</span></NavLink>
								<div
									className="
										items-center inline-flex
										cursor-pointer
										hover:text-pulse-blue"
									onClick={handleLogOut}
								>
									<span className="
										mr-1
										text-xl
										material-symbols-outlined">logout</span>
									<span>Logout</span>
								</div>
							</div>
						</div>
					</PopoverPanel>
				</Popover>
			</div>
		</div>
	)
}

const HeaderLink = ({text, dashboard}: { text: string, dashboard: Dashboards }) => {
	const {pathname} = useLocation()

	return (
		<NavLink
			to={dashboardPaths[dashboard]}
			className={classNames('block mt-4 mr-6',
				'text-black',
				'lg:inline-block lg:mt-0',
				'hover:text-pulse-blue', {
					'text-pulse-blue': pathname === dashboardPaths[dashboard]
				})}
		>
			{text}
		</NavLink>
	)
}

export default DashboardHeader
