import {useApiActions} from './index'
import {
	ChartData,
	IntegrationChartDataModel,
	IntegrationFilter,
	IntegrationGuidesData,
	IntegrationStatusData,
	IntegrationStatusTimeModel,
	IntegrationTableData,
	MrrSummary,
	StatusChangesData,
	StatusChartData
} from '../../types/integration'
import {Commercial} from '../../types/integration'

export const useIntegrationApi = () => {
	const {callApi} = useApiActions('integration')

	const getCommercials = async (filter: IntegrationFilter): Promise<Commercial[]> => {
		return await callApi<Commercial[], string>({
			query: filter,
			path: 'commercials'
		})
	}

	const getCompletedChartData = async (filter: IntegrationFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'completed-chart'
		})
	}

	const getDaysInStatus = async (filter: IntegrationFilter): Promise<IntegrationStatusTimeModel> => {
		return await callApi<IntegrationStatusTimeModel, string>({
			query: filter,
			path: 'time-status'
		})
	}

	const getDaysIntegrating = async (filter: IntegrationFilter): Promise<number> => {
		return await callApi<number, string>({
			query: filter,
			path: 'time'
		})
	}

	const getDaysIntegratingChartData = async (filter: IntegrationFilter): Promise<IntegrationChartDataModel> => {
		return await callApi<IntegrationChartDataModel, string>({
			query: filter,
			path: 'time-chart'
		})
	}

	const getGuidesChartData = async (filter: IntegrationFilter): Promise<IntegrationGuidesData> => {
		return await callApi<IntegrationGuidesData, string>({
			query: filter,
			path: 'guides-chart'
		})
	}

	const getIntegrationData = async (filter: IntegrationFilter): Promise<IntegrationTableData> => {
		return await callApi<IntegrationTableData, string>({
			query: filter,
			path: 'table'
		})
	}

	const getIntegrationMrrChartData = async (filter: IntegrationFilter): Promise<ChartData> => {
		return await callApi<ChartData, string>({
			query: filter,
			path: 'mrr-chart'
		})
	}

	const getMrrSummary = async (filter: IntegrationFilter): Promise<MrrSummary> => {
		return await callApi<MrrSummary, string>({
			query: filter,
			path: 'mrr'
		})
	}

	const getStatusChanges = async (filter: IntegrationFilter): Promise<StatusChangesData[]> => {
		return await callApi<StatusChangesData[], string>({
			query: filter,
			path: 'status-changes'
		})
	}

	const getStatusChartData = async (filter: IntegrationFilter): Promise<StatusChartData> => {
		return await callApi<StatusChartData, string>({
			query: filter,
			path: 'status-chart'
		})
	}

	const getStatusSummary = async (filter: IntegrationFilter): Promise<IntegrationStatusData> => {
		return await callApi<IntegrationStatusData, string>({
			query: filter,
			path: 'status'
		})
	}

	return {
		getCommercials,
		getCompletedChartData,
		getDaysInStatus,
		getDaysIntegrating,
		getDaysIntegratingChartData,
		getGuidesChartData,
		getIntegrationData,
		getIntegrationMrrChartData,
		getMrrSummary,
		getStatusChanges,
		getStatusChartData,
		getStatusSummary
	}
}
