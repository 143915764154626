import React from 'react'
import SecondaryValueCard from './Common/SecondaryValueCard/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewPriceToCustomerSummary = () => {
	const {getAverageTransactionRate} = usePulseApi()
	const value = useApiValue(filterState, getAverageTransactionRate, 0)

	return (
		<SecondaryValueCard
			value={value.data + '%'}
			header="Average Refund Protect Fee"
			tooltipText="This refers to the average fee charged to customers for selecting the Refund Protect option through our widget.
				This fee is calculated based on the total number of bookings where Refund Protect is opted in,
				divided by the total amount collected from those bookings."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewPriceToCustomerSummary
