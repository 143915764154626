import React, {useEffect} from 'react'
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import moment from 'moment'
import {dateFormat} from '../../../utils/date'
import {useApiValue} from '../../../hooks/api'
import {filterState, selectedTransactionState, tableFilterState, tablePageState,} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import {TableRow} from '../../../types/pulse-data'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import TransactionCheckbox from './Common/Transactions/TransactionCheckbox'
import TransactionPager from './Common/Transactions/TransactionPager'
import TransactionBatchActions from './Common/Transactions/TransactionBatchActions'
import TransactionSearch from './Common/Transactions/TransactionSearch'
import classNames from 'classnames'
import TransactionFlags from './Common/Transactions/TransactionFlags'
import {formatNumberMember} from '../../../utils/number'

const TransactionTable = () => {
	const resetPage = useResetRecoilState(tablePageState)
	const filter = useRecoilValue(filterState)
	const {getTransactions, getTransactionsVolumeSummary} = usePulseApi()
	const transactions = useApiValue(tableFilterState, getTransactions, {rows: []})
	const transactionsVolumeSummary = useApiValue(tableFilterState, getTransactionsVolumeSummary, 0)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const setSelectedTransaction = useSetRecoilState(selectedTransactionState)
	const handleModalOpen = (transaction: TableRow) => setSelectedTransaction(transaction)

	useEffect(() => {
		resetPage()
	}, [filter, resetPage])

	return (
		<>
			<div className="
				mb-3
				text-sm text-pulse-label-gray uppercase"
			>
				Total Transactions:
				{!transactionsVolumeSummary.isLoading && (
					<span className="ml-1 font-medium">{formatNumberMember(transactionsVolumeSummary.data)}</span>
				)}
				{transactionsVolumeSummary.isLoading && (
					<span className="ml-1 font-medium">Calculating...</span>
				)}
			</div>
			<div className="
				mb-4
				lg:flex lg:items-center lg:space-x-8 lg:text-center"
			>
				<div className="sm:flex sm:items-center sm:space-x-8 sm:text-center">
					<TransactionBatchActions/>
					<TransactionSearch/>
				</div>
				<TransactionFlags/>
			</div>
			<LoadingSpinner
				isLoading={transactions.isLoading}
				className="flex items-center justify-center min-h-52 w-full"
			>
				<>
					<div className="
						w-full
						text-sm
						lg:hidden"
					>
						{transactions.data.rows.map((row, index) => (
							<div
								key={index}
								className={classNames('flex items-center mb-4 space-x-4', {
									'opacity-50': row.isCancelled
								})}
							>
								<div className="flex-1">
									<TransactionCheckbox
										transactionId={row.transactionId}
										isCancelled={row.isCancelled}
									/>
								</div>
								<div className="
									gap-3 grid grid-cols-[175px_auto] pb-4 w-full
									border-b border-gray-200"
								>
									<CardHeader title="Booking Ref."/>
									<div
										className="
											overflow-ellipsis overflow-hidden
											text-left text-pulse-blue underline
											cursor-pointer"
										onClick={() => handleModalOpen(row)}
									>
										{row.transactionReferenceId}
									</div>
									<CardHeader title="Customer Name"/>
									<CardColumn value={row.customerName} style={{overflowWrap: 'anywhere'}}/>
									<CardHeader title="Booking Date"/>
									<CardColumn value={moment(row.transactionDate).format(dateFormat)}/>
									<CardHeader title="Event Date"/>
									<CardColumn value={moment(row.transactionEndDate).format(dateFormat)}/>
									<CardHeader title="Customer Fee"/>
									<CardColumn value={formatCurrency(coreCurrency, row.transactionConsumerValue, 0, 4)}/>
									<CardHeader title="RP Value"/>
									<CardColumn value={formatCurrency(coreCurrency, row.transactionValue, 0, 4)}/>
									<CardHeader title="Insured"/>
									<div className="text-left">
										{row.isInsured && (
											<span className="
												align-bottom
												text-pulse-green
												material-symbols-outlined"
											>
												check
											</span>
										)}
										{!row.isInsured && (
											<span className="
												align-bottom
												text-pulse-icon-gray
												material-symbols-outlined"
											>
												close
											</span>
										)}
									</div>
									<CardHeader title="Cancelled"/>
									<div className="text-left">
										{row.isCancelled && (
											<span className="
												align-bottom
												text-pulse-green
												material-symbols-outlined"
											>
												check
											</span>
										)}
										{!row.isCancelled && (
											<span className="
												align-bottom
												text-pulse-icon-gray
												material-symbols-outlined"
											>
												close
											</span>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
					<table className="
						hidden mb-8 table-fixed w-full
						text-sm
						lg:table"
					>
						<thead>
						<tr className="
							mb-2
							border-b border-gray-200"
						>
							<th></th>
							<TableHeader title="Booking Ref."/>
							<TableHeader title="Customer Name"/>
							<TableHeader title="Booking Date"/>
							<TableHeader title="Event Date"/>
							<TableHeader title="Customer Fee"/>
							<TableHeader title="RP Value"/>
							<TableHeader title="Insured"/>
						</tr>
						</thead>
						<tbody>
						{transactions.data.rows.map((row, index) => (
							<tr
								key={index}
								className={classNames('border-b border-gray-200', {
									'opacity-50': row.isCancelled
								})}
							>
								<td>
									<TransactionCheckbox
										transactionId={row.transactionId}
										isCancelled={row.isCancelled}
									/>
								</td>
								<td
									className="
										px-2 py-2 max-w-[14%]"
									onClick={() => handleModalOpen(row)}
								>
									<div className="flex space-x-2 w-full">
										<div className="
											flex-1
											overflow-ellipsis overflow-hidden
											text-left text-pulse-blue underline whitespace-nowrap
											cursor-pointer"
										>
											{row.transactionReferenceId}
										</div>
										{row.isCancelled && (
											<div className="
												px-1
												bg-[#fdcdcd]
												leading-1 text-[.5rem] uppercase
												rounded"
											>
												Cancelled
											</div>
										)}
									</div>
								</td>
								<TableColumn value={row.customerName}/>
								<TableColumn value={moment(row.transactionDate).format(dateFormat)}/>
								<TableColumn value={moment(row.transactionEndDate).format(dateFormat)}/>
								<TableColumn value={formatCurrency(coreCurrency, row.transactionConsumerValue, 0, 4)}/>
								<TableColumn value={formatCurrency(coreCurrency, row.transactionValue, 0, 4)}/>
								<td className="
									px-2 py-2
									text-left"
								>
									{row.isInsured && (
										<span className="
											align-bottom
											text-pulse-green
											material-symbols-outlined">check</span>
									)}
									{!row.isInsured && (
										<span className="
											align-bottom
											text-pulse-icon-gray
											material-symbols-outlined"
										>
											close
										</span>
									)}
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</>
			</LoadingSpinner>
			<TransactionPager data={transactions.data}/>
		</>
	)
}

const TableHeader = (props: { title: string }) => {
	return (
		<th className="
			px-2 py-2 w-[14%]
			font-medium text-left">{props.title}</th>
	)
}

const CardHeader = (props: { title: string }) => {
	return (<div className="font-medium text-left">{props.title}</div>)
}

const TableColumn = (props: { value: string }) => {
	return (
		<td className="
			px-2 py-2
			text-left">{props.value}</td>
	)
}

const CardColumn = (props: { value: string, style?: React.CSSProperties | undefined }) => {
	return (<div className="text-left" style={props.style}>{props.value}</div>)
}

export default TransactionTable
