import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import classNames from 'classnames'

interface PrimaryVolumeCardProps {
	className?: string,
	header: string,
	icon: string,
	isLoading: boolean,
	tooltipText: string,
	value?: string,
	valueClassName: string
}

const PrimaryVolumeCard = (props: PrimaryVolumeCardProps) => {
	const id = uuidv4()

	return (
		<div className={classNames('flex h-full px-7 py-3 relative',
			'rounded-lg',
			props.className, {
				'bg-pulse-gray': !props.className
			})}
		>
			<LoadingSpinner
				isLoading={props.isLoading}
				className="h-full w-full">
				<>
					<div className="mr-5 flex-none">
						<div className="flex h-full items-center">
							<span className="
								text-3xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								{props.icon}
							</span>
						</div>
					</div>
					<div>
						<div className="text-xs text-pulse-label-gray">
							{props.header}
						</div>
						<div className={`
							mb-1 text-2xl
							${props.valueClassName}`}
						>
							{props.value}
						</div>
						<Tooltip
							id={`${id}-popup`}
							tooltipTitle={props.header}
							tooltipText={props.tooltipText}
						/>
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default PrimaryVolumeCard
