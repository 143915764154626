import React from 'react'
import SecondaryValueCard from './Common/SecondaryValueCard/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'

const OverviewRevenuePerTransactionSummary = () => {
	const {getAverageTransactionRevenue} = usePulseApi()
	const value = useApiValue(filterState, getAverageTransactionRevenue, 0)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	return (
		<SecondaryValueCard
			value={formatCurrency(coreCurrency, value.data, 0, 4)}
			header="Average Revenue per Transaction"
			tooltipText="This represents the average amount of Refund Protect fees received for each transaction where the customer has opted into the Refund Protect service.
				It is calculated by dividing the total revenue generated from Refund Protect fees by the total number of transactions."
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewRevenuePerTransactionSummary
