import React, {ReactElement, useEffect, useRef, useState} from 'react'
import {Dialog} from '@headlessui/react'
import {SetterOrUpdater} from 'recoil'
import DefaultButton from '../Buttons/DefaultButton'

interface SupportCardProps {
	title: string,
	icon: string,
	text: string,
	popupTitle: string,
	popupBody: ReactElement,
	setButtonRef?: SetterOrUpdater<HTMLDivElement | null>
}

const SupportCard = (props: SupportCardProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const handleModalOpen = () => setIsOpen(true)
	const handleModalClose = () => setIsOpen(false)
	const buttonRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (props.setButtonRef) {
			props.setButtonRef(buttonRef.current)
		}
	})

	return (
		<>
			<p className="mb-2 text-lg">{props.title}</p>
			<div
				className="
					max-w-80 min-h-24 relative
					bg-black text-white
					rounded-lg
					cursor-pointer
					hover:opacity-60"
				onClick={handleModalOpen}
				ref={buttonRef}
			>
				<div className="flex items-center text-white">
					<span className="
						mx-4 z-10
						text-5xl
						material-symbols-outlined"
					>
						{props.icon}
					</span>
					<p className="
						my-6 mr-6 z-10
						text-lg leading-5"
					>
						{props.text}
					</p>
				</div>
				<div className="absolute bottom-0 flex items-center -right-4 top-0">
					<span className="
						opacity-20
						text-9xl text-bold text-white
						material-symbols-outlined"
					>
						arrow_forward
					</span>
				</div>
			</div>
			<Dialog
				open={isOpen}
				onClose={handleModalClose}
				className="relative z-50"
			>
				<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
				<div className="
					fixed flex items-center justify-center p-4 w-screen
					inset-0"
				>
					<Dialog.Panel className="
						max-w-sm min-w-[90%] mx-auto px-6 py-12 relative
						bg-white rounded
						md:min-w-[50%]"
					>
						<div
							className="
								absolute right-4 top-4
								cursor-pointer"
							onClick={handleModalClose}
						>
							<span className="material-symbols-outlined">close</span>
						</div>
						<Dialog.Title className="
							mb-6 pb-6
							font-medium
							border-b border-pulse-icon-gray"
						>
							{props.popupTitle}
						</Dialog.Title>
						<Dialog.Description as="div">
							{props.popupBody}
							<p className="mt-12">
								<DefaultButton text="Close" onClick={handleModalClose}/>
							</p>
						</Dialog.Description>
					</Dialog.Panel>
				</div>
			</Dialog>
		</>
	)
}

export default SupportCard
