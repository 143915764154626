import React, {useEffect, useState} from 'react'
import {Doughnut, Line} from 'react-chartjs-2'
import {FixedHeightChartCard} from '../../../../components/Card/FixedHeightChartCard'
import {ChartHeightValue, tooltipList} from '../../../../utils/chart'
import {useGetChartData} from '../../../../hooks/useGetChartDataSummary'
import {ChartCard} from '../../../../components/Card/ChartCard'
import {getColorPalette} from '../../../../utils/colors'
import {useIntegrationApi} from '../../../../hooks/api/integration'
import {useApiValue} from '../../../../hooks/api'
import {defaultIntegrationChartData, IntegrationChartDataModel} from '../../../../types/integration'
import {integrationFilterState, monthsInDateRangeState} from '../../../../state/integration'

export const DaysIntegratingChart = () => {
	const {getDaysIntegratingChartData} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getDaysIntegratingChartData, defaultIntegrationChartData)
	const {chartOptions, getChartData} = useGetChartData(data.chartData, monthsInDateRangeState)

	return (
		<FixedHeightChartCard
			title={`Average days to integrate`}
			titleClass="card-title-info"
			height={ChartHeightValue['small']}
			tooltip={tooltipList.IntegratingDays}
		>
			<Line
				data={getChartData()}
				options={chartOptions}
				className="main-chart-max-height"
			/>
		</FixedHeightChartCard>
	)
}

export const DaysIntegratingPie = () => {
	const [pieData, setPieData] = useState<number[]>([])
	const [pieLabels, setPieLabels] = useState<string[]>([])

	const {getDaysIntegratingChartData} = useIntegrationApi()
	const {data} = useApiValue(integrationFilterState, getDaysIntegratingChartData, {} as IntegrationChartDataModel)

	useEffect(() => {
		if (data && data.pieChartData) {
			const regionLabels = data.pieChartData.map(item => item.label)
			const regionTime = data.pieChartData.map(item => item.time)
			setPieLabels(regionLabels)
			setPieData(regionTime)
		}
	}, [data])

	const chartData = {
		labels: pieLabels,
		datasets: [{
			label: 'Days Integrating',
			data: pieData,
			backgroundColor: getColorPalette(pieLabels.length),
			hoverOffset: 4
		}]
	}

	return (
		<ChartCard
			title="Days Integrating"
			titleClass="card-title-warning"
		>
			<Doughnut
				options={{maintainAspectRatio: false}}
				height={400}
				width={400}
				data={chartData}
			/>
		</ChartCard>
	)
}
