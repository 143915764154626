import {atom, selector} from 'recoil'
import {
	batchAction,
	dateRange,
	graphFilterType,
	PulseFilter,
	PulseTableFilter,
	PulseUserFilter
} from '../types/pulse'
import {
	dateFromState,
	formattedDateFromState,
	formattedDateToState,
	selectedCoreCurrencyState,
	selectedDateRangeState,
	selectedGraphFiltersState,
	selectedMemberIdState,
	selectedRegionIdState
} from './pulse-filters'
import {loggedInUserState} from './auth'
import {ExportData, SalesRegion, TableRow, UserDetail} from '../types/pulse-data'
import moment from 'moment'
import {Member} from '../types'

export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

const keyPrefix = 'pulse'

export const filterState = selector<PulseFilter>({
	key: `${keyPrefix}-filter`,
	get: ({get}) => {
		return {
			dateFrom: get(formattedDateFromState),
			dateTo: get(formattedDateToState),
			memberId: get(selectedMemberIdState),
			regionId: get(selectedRegionIdState),
			userName: get(loggedInUserState),
			coreCurrencyCode: get(selectedCoreCurrencyState),
			dateRange: get(selectedDateRangeState).toString(),
			graphFilters: get(selectedGraphFiltersState)
		}
	}
})

export const selectedRegionFilterState = selector<graphFilterType | undefined>({
	key: `${keyPrefix}-selectedRegionFilter`,
	get: ({get}) => {
		const graphFilters = get(selectedGraphFiltersState)

		if (graphFilters.includes(graphFilterType.RefundProtectValue)) {
			return graphFilterType.RefundProtectValue
		}

		if (graphFilters.includes(graphFilterType.GrossBookingValue)) {
			return graphFilterType.GrossBookingValue
		}

		if (graphFilters.includes(graphFilterType.AverageConversionRate)) {
			return graphFilterType.AverageConversionRate
		}

		if (graphFilters.includes(graphFilterType.GrossBookingVolume)) {
			return graphFilterType.GrossBookingVolume
		}

		if (graphFilters.includes(graphFilterType.RevenuePerDollar)) {
			return graphFilterType.RevenuePerDollar
		}

		return undefined
	}
})

export const tableFilterState = selector<PulseTableFilter>({
	key: `${keyPrefix}-tableFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			page: get(tablePageState),
			pageSize: get(tablePageSizeState),
			sortColumn: get(tableSortColumnState),
			sortDirection: get(tableSortDirectionState),
			search: get(tableSearchState),
			isInsured: get(tableIsInsuredState),
			autoRefresh: get(transactionTableUpdateTriggerState)
		}
	}
})

export const scrollState = atom<boolean>({
	key: `${keyPrefix}-scroll`,
	default: false
})

export const calendarDatesState = atom<Value>({
	key: `${keyPrefix}-calendarDates`,
	default: null
})

export const tablePageState = atom<number>({
	key: `${keyPrefix}-tablePage`,
	default: 1
})

export const tablePageSizeState = atom<number>({
	key: `${keyPrefix}-tablePageSize`,
	default: 10
})

export const tableSortColumnState = atom<string>({
	key: `${keyPrefix}-tableSortColumn`,
	default: 'transactionId'
})

export const tableSortDirectionState = atom<string>({
	key: `${keyPrefix}-tableSortDirection`,
	default: 'desc'
})

export const tableSearchState = atom<string>({
	key: `${keyPrefix}-tableSearch`,
	default: ''
})

export const tableIsInsuredState = atom<boolean>({
	key: `${keyPrefix}-tableIsInsured`,
	default: true
})

export const transactionTableUpdateTriggerState = atom<number>({
	key: `${keyPrefix}-transactionTableUpdateTrigger`,
	default: 0
})

export const toastTextState = atom<string>({
	key: `${keyPrefix}-toastText`,
	default: ''
})

export const selectedTransactionsState = atom<number[]>({
	key: `${keyPrefix}-selectedTransactions`,
	default: []
})

export const transactionBatchActionState = atom<batchAction>({
	key: `${keyPrefix}-transactionBatchAction`,
	default: batchAction.None
})

export const selectedTransactionState = atom<TableRow | null>({
	key: `${keyPrefix}-selectedTransaction`,
	default: null
})

export const exportDataState = atom<ExportData | null>({
	key: `${keyPrefix}-exportData`,
	default: null
})

export const partnershipButtonReferenceState = atom<HTMLDivElement | null>({
	key: `${keyPrefix}-partnershipButtonReference`,
	default: null
})

export const userDetailState = atom<UserDetail>({
	key: `${keyPrefix}-userDetail`,
	default: {}
})

export const membersState = atom<Member[]>({
	key: `${keyPrefix}-members`,
	default: []
})

export const salesRegionsState = atom<SalesRegion[]>({
	key: `${keyPrefix}-salesRegions`,
	default: []
})

export const titleSuffixState = selector<string>({
	key: `${keyPrefix}-titleSuffix`,
	get: ({get}) => {
		const currentDate = moment()
		const date = get(selectedDateRangeState)
		const fromDate = get(dateFromState)
		const formattedFromDate = get(formattedDateFromState)
		const formattedToDate = get(formattedDateToState)

		switch (date) {
			case dateRange.YearToDate:
				return `YTD ${currentDate.format('YYYY')}`
			case dateRange.Year:
				return currentDate.add(-1, 'year').format('YYYY')
			case dateRange.Custom:
				return `${formattedFromDate} - ${formattedToDate}`
			case dateRange.Month:
				return fromDate?.format('MMMM YY') || ''
			case dateRange.AllTime:
				return 'All Time'
			default:
				return ''
		}
	}
})

export const userFilterState = selector<PulseUserFilter>({
	key: `${keyPrefix}-userFilter`,
	get: ({get}) => {
		return {
			userName: get(loggedInUserState)
		}
	}
})

export const dashboardLoadingState = atom<boolean>({
	key: `${keyPrefix}-dashboardLoading`,
	default: true
})

export const overviewChartFilterState = selector<PulseFilter>({
	key: `${keyPrefix}-overviewChartFilter`,
	get: ({get}) => {
		return {
			...get(filterState),
			graphFilters: [
				graphFilterType.YourRevenue,
				graphFilterType.AverageConversionRate,
				graphFilterType.GrossBookingVolume,
				graphFilterType.RefundProtectVolume
			]
		}
	}
})