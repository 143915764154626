import React from 'react'
import PrimaryVolumeCard from './Common/PrimaryVolumeCard/PrimaryVolumeCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {formatNumberMember} from '../../../utils/number'

const OverviewBookingVolumeSummary = () => {
	const {getBookingVolumeSummary} = usePulseApi()
	const value = useApiValue(filterState, getBookingVolumeSummary, 0)

	return (
		<PrimaryVolumeCard
			value={formatNumberMember(value.data)}
			header="Gross Booking Volume"
			icon="people"
			tooltipText="This refers to the total number of bookings or transactions processed over a specific period.
				It accounts for all confirmed bookings, whether with or without Refund Protect."
			valueClassName="text-black"
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewBookingVolumeSummary
