import React from 'react'
import PrimaryValueCard from './Common/PrimaryValueCard/PrimaryValueCard'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {usePulseApi} from '../../../hooks/api/pulse'
import useGetGraphFilterLabel from '../hooks/useGetGraphFilterLabel'
import {graphFilterType} from '../../../types/pulse'

const OverviewProfitValueSummary = () => {
	const {getProfitValueSummary, getProfitRateTrend} = usePulseApi()
	const profitValue = useApiValue(filterState, getProfitValueSummary, 0)
	const trendRate = useApiValue(filterState, getProfitRateTrend, 0)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	return (
		<PrimaryValueCard
			value={profitValue.data}
			trendValue={trendRate.data}
			header={getGraphFilterLabel(graphFilterType.YourRevenue)}
			tooltipText="This refers to the share of revenue that you receive from the Refund Protect service.
				This metric represents the financial benefits accrued to your business based on the transactions where customers opted for Refund Protect."
			className="bg-pulse-highlight-blue"
			valueClassName="text-pulse-blue"
			isLoading={profitValue.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewProfitValueSummary
