import React from 'react'
import PrimaryValueCard from './Common/PrimaryValueCard/PrimaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'

const OverviewRefundProtectValueSummary = () => {
	const {getRefundProtectValueSummary, getRefundProtectRateTrend} = usePulseApi()
	const value = useApiValue(filterState, getRefundProtectValueSummary, 0)
	const trendRate = useApiValue(filterState, getRefundProtectRateTrend, 0)

	return (
		<PrimaryValueCard
			value={value.data}
			trendValue={trendRate.data}
			header="Refund Protect Value"
			tooltipText="This refers to the total revenue generated from the Refund Protect service offered to customers.
				This value encompasses all fees collected from customers who opt for the Refund Protect option at the time of booking."
			valueClassName="text-black"
			isLoading={value.isLoading || trendRate.isLoading}
		/>
	)
}

export default OverviewRefundProtectValueSummary
