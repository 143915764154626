export interface PulseFilter extends Record<string, any> {
	dateFrom: string | null
	dateTo: string | null
	memberId: number | null
	regionId: number | null
	userName: string | undefined
	coreCurrencyCode: string | null
	dateRange: string | null,
	graphFilters: graphFilterType[] | null
}

export enum dateRange {
	Month,
	Year,
	YearToDate,
	Custom,
	AllTime
}

export interface PulseTableFilter extends PulseFilter {
	page: number
	pageSize: number
	sortColumn: string
	sortDirection: string
	isInsured: boolean
}

export interface PulseTransactionFilter extends PulseUserFilter {
	transactionIds: number[]
}

export interface PulseUserFilter extends Record<string, any> {
	userName: string | undefined
}

export enum graphFilterType {
	GrossBookingValue,
	RefundProtectValue,
	YourRevenue,
	AverageConversionRate,
	GrossBookingVolume,
	RefundProtectVolume,
	RevenuePerDollar
}

export const graphFilterName: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: 'Refund Protect Value',
	[graphFilterType.YourRevenue]: 'Your Revenue',
	[graphFilterType.GrossBookingValue]: 'Gross Booking Value',
	[graphFilterType.RefundProtectVolume]: 'Refund Protect Volume',
	[graphFilterType.GrossBookingVolume]: 'Gross Booking Volume',
	[graphFilterType.RevenuePerDollar]: 'Revenue Per $',
	[graphFilterType.AverageConversionRate]: 'Conversion Rate',
}

export const graphFilterColor: Record<graphFilterType, string> = {
	[graphFilterType.RefundProtectValue]: '#0084C4',
	[graphFilterType.YourRevenue]: '#23B77D',
	[graphFilterType.GrossBookingValue]: '#9D9DA4',
	[graphFilterType.RefundProtectVolume]: '#0084C4',
	[graphFilterType.GrossBookingVolume]: '#9D9DA4',
	[graphFilterType.RevenuePerDollar]: '#9D9DA4',
	[graphFilterType.AverageConversionRate]: '#FB4B4E',
}

export const graphValueTrends = [
	graphFilterType.YourRevenue,
	graphFilterType.RefundProtectValue,
	graphFilterType.GrossBookingValue,
	graphFilterType.AverageConversionRate
]

export const graphValueDemographics = [
	...graphValueTrends
]

export const graphVolumeTrends = [
	graphFilterType.RefundProtectVolume,
	graphFilterType.GrossBookingVolume
]

export const graphVolumeDemographics = [
	...graphVolumeTrends
]

export const graphRevenue = [
	graphFilterType.RevenuePerDollar
]

export enum settingsPageType {
	Account
}

export enum batchAction {
	None,
	Cancel
}

export const batchActionName: Record<batchAction, string> = {
	[batchAction.None]: 'Actions',
	[batchAction.Cancel]: 'Cancel Selected'
}