import React, {useEffect, useState} from 'react'
import {formatCurrency} from '../../../../../utils/currency'
import {useRecoilState, useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState, selectedDateRangeState} from '../../../../../state/pulse-filters'
import {dateRange} from '../../../../../types/pulse'
import classNames from 'classnames'
import Tooltip from '../Tooltip/Tooltip'
import {v4 as uuidv4} from 'uuid'
import LoadingSpinner from '../Spinner/LoadingSpinner'

interface PrimaryValueCardProps {
	header: string,
	tooltipText: string,
	value?: number,
	valueClassName: string,
	trendValue?: number,
	isLoading: boolean,
	className?: string
}

const PrimaryValueCard = (props: PrimaryValueCardProps) => {
	const id = uuidv4()
	const [coreCurrency] = useRecoilState(selectedCoreCurrencyState)
	const formattedCurrency = coreCurrency && props.value !== undefined ? formatCurrency(coreCurrency, props.value) : ''
	const date = useRecoilValue(selectedDateRangeState)
	const [period, setPeriod] = useState('')

	useEffect(() => {
		switch (date) {
			case dateRange.YearToDate:
			case dateRange.Year:
				setPeriod('year')
				break
			case dateRange.Month:
				setPeriod('month')
				break
		}
	}, [date])

	return (
		<div className={classNames('flex h-full min-h-32 px-7 py-5 relative',
			'rounded-lg',
			props.className, {
				'bg-pulse-gray': !props.className
			})}
		>
			<LoadingSpinner isLoading={props.isLoading} className="w-full">
				<>
					<div className="mr-2 flex-none">
						<div className="flex h-full items-center">
							<span className="
								text-4xl text-pulse-icon-gray
								material-symbols-outlined"
							>
								account_balance_wallet
							</span>
						</div>
					</div>
					<div>
						<div className="
							mb-1
							text-sm text-pulse-label-gray"
						>
							{props.header}
						</div>
						<div className={`
							mb-1
							text-4xl
							${props.valueClassName}
							lg:text-3xl md:text-2xl`}
						>
							{formattedCurrency}
						</div>
						{props.trendValue !== undefined && props.trendValue > 0 && (
							<div className="text-xs text-pulse-blue">
								<span className="
									align-middle mb-1
									text-xs
									material-symbols-outlined"
								>
									keyboard_arrow_up
								</span>
								<span className="font-medium">{props.trendValue}%</span> vs last {period}
							</div>
						)}
						{props.trendValue !== undefined && props.trendValue < 0 && (
							<div className="text-xs text-pulse-red">
								<span className="
									mb-1 align-middle
									text-xs
									material-symbols-outlined"
								>
									keyboard_arrow_down
								</span>
								<span className="font-medium">{props.trendValue * -1}%</span> vs last {period}
							</div>
						)}
						<Tooltip id={`${id}-popup`} tooltipTitle={props.header} tooltipText={props.tooltipText}/>
					</div>
				</>
			</LoadingSpinner>
		</div>
	)
}

export default PrimaryValueCard
