export const coreCurrencyDropdownData = [
	{ id: '0', value: 'GBP' },
	{ id: '1', value: 'EUR' },
	{ id: '2', value: 'USD' },
	{ id: '3', value: 'AUD' },
	{ id: '4', value: 'CAD' },
	{ id: '5', value: 'BRL' }
];

export const extendedCoreCurrencyDropdownData = [
	...coreCurrencyDropdownData,
	{ id: '6', value: 'CNY' },
	{ id: '7', value: 'INR' },
	{ id: '8', value: 'JPY' },
	{ id: '9', value: 'KRW' },
	{ id: '10', value: 'MYR' },
	{ id: '11', value: 'PHP' },
	{ id: '12', value: 'RUB' }
]