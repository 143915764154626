import React from 'react'
import SupportCard from './Common/SupportCard/SupportCard'
import {partnershipButtonReferenceState, userDetailState} from '../../../state/pulse'
import {useRecoilValue, useSetRecoilState} from 'recoil'

const DashboardSupport = () => {
	const userDetail = useRecoilValue(userDetailState)
	const commercialEmail = userDetail.commercialEmail || process.env.REACT_APP_PULSE_SUPPORT_COMMERCIAL_EMAIL
	const customerExperienceEmail = process.env.REACT_APP_PULSE_SUPPORT_CUSTOMEREXPERIENCE_EMAIL
	const financeEmail = process.env.REACT_APP_PULSE_SUPPORT_FINANCE_EMAIL
	const setPartnershipButtonReference = useSetRecoilState(partnershipButtonReferenceState)

	return (
		<>
			<h2 className="
				mb-2
				font-semibold text-2xl"
			>
				Support
			</h2>
			<div className="
				mb-4
				md:content-end md:flex md:space-x-6"
			>
				<div className="
					flex flex-col mb-4 justify-end
					md:mb-0"
				>
					<SupportCard
						icon="support_agent"
						title="Want to optimise your performance?"
						text="Get in touch with your Partnership Manager"
						setButtonRef={setPartnershipButtonReference}
						popupTitle="Partnership Manager"
						popupBody={(<>
							{userDetail.commercialName && (
								<p className="font-medium mb-4">{userDetail.commercialName}</p>
							)}
							<p className="mb-2">
								<span className="
									align-middle mr-2
									text-pulse-icon-gray
									material-symbols-outlined"
								>
									mail
								</span>
								<a
									href={`mailto:${commercialEmail}`}
									className="text-black hover:opacity-60">{commercialEmail}</a>
							</p>
							{userDetail.commercialPhone && (
								<p className="mb-2">
									<span className="
										align-middle mr-2
										text-pulse-icon-gray
										material-symbols-outlined"
									>
										call
									</span>
									<span>{userDetail.commercialPhone}</span>
								</p>
							)}
						</>)}/>
				</div>
				<div className="
					flex flex-col justify-end mb-4
					md:mb-0"
				>
					<SupportCard
						icon="support_agent"
						title="Do you need help?"
						text="Contact our Support Team"
						popupTitle="Support Team"
						popupBody={(<>
							<p className="mb-2">
								<span className="
									align-middle mr-2
									text-pulse-icon-gray
									material-symbols-outlined"
								>
									mail
								</span>
								<a
									href={`mailto:${customerExperienceEmail}`}
									className="text-black hover:opacity-60"
								>
									{customerExperienceEmail}
								</a>
							</p>
						</>)}/>
				</div>
				<div className="
					flex flex-col justify-end mb-4
					md:mb-0"
				>
					<SupportCard
						icon="support_agent"
						title="Do you need invoicing help?"
						text="Contact our Finance Team"
						popupTitle="Finance Team"
						popupBody={(<>
							<p className="mb-2">
								<span className="
									align-middle mr-2
									text-pulse-icon-gray
									material-symbols-outlined"
								>
									mail
								</span>
								<a
									href={`mailto:${financeEmail}`}
									className="text-black hover:opacity-60"
								>
									{financeEmail}
								</a>
							</p>
						</>)}/>
				</div>
			</div>
		</>
	)
}

export default DashboardSupport
