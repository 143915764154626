import React, {ReactElement, useState} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import OverviewComparisonChart from './components/OverviewComparisonChart'
import classNames from 'classnames'
import OverviewProfitValueSummary from './components/OverviewProfitValueSummary'
import OverviewRefundProtectValueSummary from './components/OverviewRefundProtectValueSummary'
import OverviewBookingValueSummary from './components/OverviewBookingValueSummary'
import OverviewRefundProtectVolumeSummary from './components/OverviewRefundProtectVolumeSummary'
import OverviewBookingVolumeSummary from './components/OverviewBookingVolumeSummary'
import OverviewConversionRateSummary from './components/OverviewConversionRateSummary'
import OverviewPriceToCustomerSummary from './components/OverviewPriceToCustomerSummary'
import OverviewRevenuePerTransactionSummary from './components/OverviewRevenuePerTransactionSummary'
import OverviewRevenuePerCurrencySummary from './components/OverviewRevenuePerCurrencySummary'
import DashboardSupport from './components/DashboardSupport'
import RefundTimeSummary from './components/RefundTimeSummary'
import RefundAutoApprovalSummary from './components/RefundAutoApprovalSummary'
import RefundEmailVolumeSummary from './components/RefundEmailVolumeSummary'
import RefundTrustPilotScoreSummary from './components/RefundTrustPilotScoreSummary'
import RefundTrustPilotReviews from './components/RefundTrustPilotReviews'
import useExportOverviewData from './hooks/useExportOverviewData'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {exportDataState, titleSuffixState, userDetailState} from '../../state/pulse'
import MinimizeIcon from './components/Common/Images/MinimizeIcon'
import MaximizeIcon from './components/Common/Images/MaximizeIcon'
import OverviewGraphChart from './components/OverviewGraphChart'
import H2 from './components/Common/Headers/H2'
import OverviewProtectionValueSummary from './components/OverviewProtectionValueSummary'

const PulseOverviewView = () => {
	const [showVolumes, setShowVolumes] = useState(false)
	const exportData = useExportOverviewData()
	const titleSuffix = useRecoilValue(titleSuffixState)
	const userDetail = useRecoilValue(userDetailState)
	const setExportDataState = useSetRecoilState(exportDataState)
	setExportDataState(exportData)

	const toggleShowVolumes = () => {
		setShowVolumes(!showVolumes)
	}

	return (
		<DashboardPageLayout>
			<>
				<section className="
					max-w-6xl mb-1 px-4 relative w-full
					lg:mx-auto lg:px-10"
				>
					<H2>Overview - {titleSuffix}</H2>
					<ViewRow>
						<>
							<ViewItem>
								<OverviewProfitValueSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewRefundProtectValueSummary/>
							</ViewItem>
							<ViewItem>
								<OverviewBookingValueSummary/>
							</ViewItem>
						</>
					</ViewRow>
					<div
						className="
							-bottom-3 hidden min-w-36 mx-4 px-2 py-1 right-10
							bg-white
							text-xs uppercase
							border border-pulse-icon-gray rounded-full
							cursor-pointer
							md:absolute md:block md:mx-0
							hover:opacity-60"
						onClick={toggleShowVolumes}
					>
						{showVolumes && (
							<>
								<MinimizeIcon/>
								<span className="align-middle ml-1">Hide Volumes</span>
							</>
						)}
						{!showVolumes && (
							<>
								<MaximizeIcon/>
								<span className="align-middle ml-1">Show Volumes</span>
							</>
						)}
					</div>
				</section>
				<section className="
					w-full max-w-6xl px-4
					lg:mx-auto lg:px-10"
				>
					<ViewRow className={classNames('overflow-hidden',
						'duration-200 ease-in-out transition-all',
						'md:max-h-0', {
							'md:max-h-screen': showVolumes
						})}
					>
						<>
							<ViewItem className="mt-3">
								<>
									{userDetail.isProtectGroup === true && (<OverviewProtectionValueSummary/>)}
								</>
							</ViewItem>
							<ViewItem className="mt-3">
								<OverviewRefundProtectVolumeSummary/>
							</ViewItem>
							<ViewItem className="mt-3">
								<OverviewBookingVolumeSummary/>
							</ViewItem>
						</>
					</ViewRow>
				</section>
				<section className="
					hidden max-w-6xl mt-4 px-4 w-full
					md:block lg:mx-auto lg:px-10"
				>
					<OverviewComparisonChart/>
				</section>
				<section className="
					max-w-6xl mt-2 px-4 w-full
					lg:mx-auto lg:px-10"
				>
					<ViewGrid>
						<>
							<OverviewConversionRateSummary/>
							<OverviewPriceToCustomerSummary/>
							<OverviewRevenuePerTransactionSummary/>
							<OverviewRevenuePerCurrencySummary/>
						</>
					</ViewGrid>
				</section>
				<section className="
					max-w-6xl mt-4 pb-8 px-4 w-full
					lg:mx-auto lg:px-10"
				>
					<OverviewGraphChart/>
				</section>
				<section className="bg-pulse-gray">
					<div className="
						max-w-6xl px-4 py-8 w-full
						bg-pulse-gray
						lg:mx-auto lg:px-10"
					>
						<h2 className="
							mb-2
							font-semibold text-2xl"
						>
							Customer Experience
						</h2>
						<ViewGrid>
							<>
								<RefundTimeSummary/>
								<RefundAutoApprovalSummary/>
								<RefundEmailVolumeSummary/>
								<RefundTrustPilotScoreSummary/>
							</>
						</ViewGrid>
						<div className="mt-12">
							<RefundTrustPilotReviews/>
						</div>
					</div>
				</section>
				<section className="
					max-w-6xl px-4 py-8 w-full
					lg:mx-auto lg:px-10"
				>
					<DashboardSupport/>
				</section>
			</>
		</DashboardPageLayout>
	)
}

const ViewGrid = (props: { children: ReactElement, className?: string }) => (
	<div className={classNames(
		'grid grid-cols-1 gap-4',
		'md:grid-cols-2 lg:grid-cols-4',
		props.className)}
	>
		{props.children}
	</div>
)

const ViewRow = (props: { children: ReactElement, className?: string }) => (
	<div className={classNames(
		'md:flex md:space-x-4',
		props.className)}
	>
		{props.children}
	</div>
)

const ViewItem = (props: { children: ReactElement, className?: string }) => (
	<div className={classNames(
		'flex-1 mb-4 md:mb-0',
		props.className)}
	>
		{props.children}
	</div>
)

export default PulseOverviewView
