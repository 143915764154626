import React from 'react'
import SecondaryValueCard from './Common/SecondaryValueCard/SecondaryValueCard'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatCurrency} from '../../../utils/currency'
import useGetGraphFilterLabel from '../hooks/useGetGraphFilterLabel'
import {graphFilterType} from '../../../types/pulse'

const OverviewRevenuePerCurrencySummary = () => {
	const {getTransactionRevenuePerDollar} = usePulseApi()
	const value = useApiValue(filterState, getTransactionRevenuePerDollar, 0)
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)
	const formattedValue = formatCurrency(coreCurrency, value.data, 0, 4)
	const getGraphFilterLabel = useGetGraphFilterLabel()

	return (
		<SecondaryValueCard
			value={formattedValue}
			header={getGraphFilterLabel(graphFilterType.RevenuePerDollar)}
			tooltipText={`This is a performance metric that measures the amount of revenue generated for every ${coreCurrency} spent on Refund Protect fees.`}
			isLoading={value.isLoading}
		/>
	)
}

export default OverviewRevenuePerCurrencySummary
